import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";
import { HelmetTitle } from "../components/HelmetTitle";

const TermsConditions = () => {
  return (
    <>
      <HelmetTitle title="Allo — Terms of Service" />
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="hero" className="text-center">
                    Terms of Service
                  </Title>
                  <Text variant="small">
                    <p>Last updated: 08/15/2022</p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Introduction
                  </Title>
                  <Text variant="small">
                    <p>
                      Welcome to <b>Jomo Labs LLC</b> (&ldquo;<b>Company</b>
                      &rdquo;, &ldquo;<b>we</b>&rdquo;, &ldquo;<b>our</b>
                      &rdquo;, &ldquo;
                      <b>us</b>&rdquo;)! These Terms of Service (&ldquo;
                      <b>Terms</b>&rdquo;, &ldquo;
                      <b>Terms of Service</b>&rdquo;) govern your use of our
                      mobile application Allo operated by Jomo Labs LLC. Our
                      Privacy Policy also governs your use of our Service and
                      explains how we collect, safeguard and disclose
                      information that results from your use of our web pages.
                      Please read it here http://jomolabs.co/privacy.
                    </p>
                    <p>
                      Your agreement with us includes these Terms and our
                      Privacy Policy (&ldquo;<b>Agreements</b>&rdquo;). You
                      acknowledge that you have read and understood Agreements,
                      and agree to be bound by them.
                    </p>
                    <p>
                      We are not a bank, financial planner, broker or asset
                      manager. While the Service provided by us may provide
                      assistance in helping you manage your finances, you should
                      consult with a professional financial advisor and/or an
                      attorney who is fully aware of your individual situation
                      or circumstances before making investment decisions or
                      deciding on significant changes to your personal financial
                      strategy.
                    </p>
                    <p>
                      If you do not agree with (or cannot comply with)
                      Agreements, then you may not use the Service, but please
                      let us know by emailing at support@allo.finance so we can
                      try to find a solution. These Terms apply to all visitors,
                      users and others who wish to access or use Service.
                    </p>
                    <p>Thank you for being responsible.</p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Accounts
                  </Title>
                  <Text variant="small">
                    <p>
                      When you create an account with us, you guarantee that you
                      are above the age of 18, and that the information you
                      provide us is accurate, complete, and current at all
                      times. Inaccurate, incomplete, or obsolete information may
                      result in the immediate termination of your account on
                      Service.
                    </p>
                    <p>
                      You are responsible for maintaining the confidentiality of
                      your account and password, including but not limited to
                      the restriction of access to your computer and/or account.
                      You agree to accept responsibility for any and all
                      activities or actions that occur under your account and/or
                      password, whether your password is with our Service or a
                      third-party service. You must notify us immediately upon
                      becoming aware of any breach of security or unauthorized
                      use of your account.
                    </p>
                    <p>
                      We reserve the right to refuse service, terminate
                      accounts, remove or edit content, or cancel orders in our
                      sole discretion.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Subscriptions
                  </Title>
                  <Text variant="small">
                    <p>
                      Access to the Service, or certain features of the Service,
                      may require you to pay fees on a subscription basis.
                      Before you pay for the subscription, you will have an
                      opportunity to review the fees that you will be charged. A
                      valid payment method, including a credit card, is required
                      to process the payment for your Subscription. By
                      submitting such payment information, you automatically
                      authorize the Company or its third-party service providers
                      to periodically charge you for an automatically renewing
                      subscription requiring recurring payments at the stated
                      frequency (e.g., monthly or annual) (each a “Subscription
                      Period”) until canceled.
                    </p>
                    <p>
                      <b>Free Trial and Promotions</b>
                    </p>
                    <p>
                      The Company may offer promotional trial periods during
                      which you can use the Service for free or at a promotional
                      rate. Such trial or promotional trial periods are subject
                      to these Terms except as otherwise stated in the
                      promotional offer, including which users are eligible for
                      the promotional subscription. Eligibility is initially
                      determined at the time of the order. Free trial and
                      promotional rates cannot be applied retroactively.
                    </p>
                    <p>
                      <b>Price Changes</b>
                    </p>
                    <p>
                      The Company may modify Subscription fees. Any Subscription
                      fee change will become effective at the end of the then-
                      current Subscription Period.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Cancelation and Termination
                  </Title>
                  <Text variant="small">
                    <p>
                      <b>Cancelation by You</b>
                    </p>
                    <p>
                      When you cancel a subscription, you cancel only future
                      charges associated with your subscription. You may
                      initiate your cancellation at any time, but the
                      cancellation will become effective at the end of your
                      current Subscription Period. In order to avoid future
                      charges, you must cancel your subscription at least 24
                      hours before the end of your current Subscription Period.
                      If you purchased through a third party (e.g., app store),
                      you must cancel through that third party. Purchases
                      through third parties are subject to that third party’s
                      cancellation policies and procedures.
                    </p>
                    <p>
                      After canceling a subscription, you will continue to have
                      access to the Service for the remainder of your current
                      Subscription Period for which you have already paid
                      (unless we provide you with a refund or otherwise allow
                      you to use the unused portion towards another service,
                      product, or subscription).
                    </p>
                    <p>
                      <b>Termination by the Company</b>
                    </p>
                    <p>
                      If you wish to terminate your account, you may cancel your
                      subscription at anytime and discontinue using the Service.
                    </p>
                    <p>
                      We may terminate or suspend your account and bar access to
                      Service immediately, without prior notice or liability,
                      under our sole discretion, for any reason whatsoever and
                      without limitation, including but not limited to a breach
                      of Terms. If we cancel your subscription, we may give you
                      a prorated refund based on the amount of time remaining
                      that you cannot use, provided, however, that your actions
                      or your use of the Service did not violote these Terms,
                      any applicable law, or has harmed the Company or another
                      user or entity.
                    </p>

                    <p>
                      All provisions of Terms which by their nature should
                      survive termination shall survive termination, including,
                      without limitation, ownership provisions, warranty
                      disclaimers, indemnity and limitations of liability.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Communications
                  </Title>
                  <Text variant="small">
                    <p>
                      By creating an Account on our Service, you agree to
                      subscribe to emails we may send. However, you may opt out
                      of receiving any, or all, of these communications from us
                      by emailing at support@allo.finance.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Prohibited Uses
                  </Title>
                  <Text variant="small">
                    <p>
                      You may use Service only for lawful purposes and in
                      accordance with Terms. You agree not to use Service:
                    </p>
                    <List>
                      <li>
                        In any way that violates any applicable national or
                        international law or regulation.
                      </li>
                      <li>
                        For the purpose of exploiting, harming, or attempting to
                        exploit or harm minors in any way by exposing them to
                        inappropriate content or otherwise.
                      </li>
                      <li>
                        To transmit, or procure the sending of, any advertising
                        or promotional material, including any &ldquo;junk
                        mail&rdquo;, &ldquo;chain letter,&rdquo;
                        &ldquo;spam,&rdquo; or any other similar solicitation.
                      </li>
                      <li>
                        To impersonate or attempt to impersonate Company, a
                        Company employee, another user, or any other person or
                        entity.
                      </li>
                      <li>
                        In any way that infringes upon the rights of others, or
                        in any way is illegal, threatening, fraudulent, or
                        harmful, or in connection with any unlawful, illegal,
                        fraudulent, or harmful purpose or activity.
                      </li>
                      <li>
                        To engage in any other conduct that restricts or
                        inhibits anyone&rsquo;s use or enjoyment of Service, or
                        which, as determined by us, may harm or offend Company
                        or users of Service or expose them to liability.
                      </li>
                    </List>

                    <p>Additionally, you agree not to:</p>
                    <List>
                      <li>
                        Use Service in any manner that could disable,
                        overburden, damage, or impair Service or interfere with
                        any other party&rsquo;s use of Service, including their
                        ability to engage in real time activities through
                        Service.
                      </li>
                      <li>
                        Use any robot, spider, or other automatic device,
                        process, or means to access Service for any purpose,
                        including monitoring or copying any of the material on
                        Service.
                      </li>
                      <li>
                        Use any manual process to monitor or copy any of the
                        material on Service or for any other unauthorized
                        purpose without our prior written consent.
                      </li>
                      <li>
                        Use any device, software, or routine that interferes
                        with the proper working of Service.
                      </li>
                      <li>
                        Introduce any viruses, trojan horses, worms, logic
                        bombs, or other material which is malicious or
                        technologically harmful.
                      </li>
                      <li>
                        Attempt to gain unauthorized access to, interfere with,
                        damage, or disrupt any parts of Service, the server on
                        which Service is stored, or any server, computer, or
                        database connected to Service.
                      </li>
                      <li>
                        Attack Service via a denial-of-service attack or a
                        distributed denial-of-service attack.
                      </li>
                      <li>
                        Take any action that may damage or falsify Company
                        rating.
                      </li>
                      <li>
                        Otherwise attempt to interfere with the proper working
                        of Service.
                      </li>
                    </List>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Analytics
                  </Title>
                  <Text variant="small">
                    <p>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </p>
                    <p>
                      <b>Amplitude</b>
                    </p>
                    <p>
                      Amplitude, Inc. (&ldquo;Amplitude&rdquo;) is an analytics
                      service that tracks and reports traffic.
                    </p>
                    <p>
                      For more information on what data Amplitude collects for
                      what purpose and how the protection of the data is
                      ensured, please visit Amplitude Privacy Policy page:
                      <a href="https://www.google.com/url?q=https://amplitude.com/privacy&amp;sa=D&amp;ust=1591918033375000">
                        https://amplitude.com/privacy
                      </a>
                      .
                    </p>
                    <p>
                      <b>Google Anlaytics</b>
                    </p>
                    <p>
                      Google Analytics is a web analytics service offered by
                      Google that tracks and reports website traffic. Google
                      uses the data collected to track and monitor the use of
                      our Service. This data is shared with other Google
                      services. Google may use the collected data to
                      contextualise and personalise the ads of its own
                      advertising network.
                    </p>
                    <p>
                      For more information on the privacy practices of Google,
                      please visit the Google Privacy Terms web page:
                      https://policies.google.com/privacy?hl=en We also
                      encourage you to review the Google's policy for
                      safeguarding your data:
                      https://support.google.com/analytics/answer/6004245.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    No Use By Minors
                  </Title>
                  <Text variant="small">
                    <p>
                      Service is intended only for access and use by individuals
                      at least eighteen (18) years old. By accessing or using
                      any of Company, you warrant and represent that you are at
                      least eighteen (18) years of age and with the full
                      authority, right, and capacity to enter into this
                      agreement and abide by all of the terms and conditions of
                      Terms. If you are not at least eighteen (18) years old,
                      you are prohibited from both the access and usage of
                      Service.
                    </p>
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Third Party Services.
                  </Title>
                  <Text variant="small">
                    <p>
                      To use some of the Services, you may need to provide
                      information such as credit card numbers, bank account
                      numbers, and other sensitive financial information, to
                      third parties. By using the Services, you agree that we
                      may collect, store, and transfer such information on your
                      behalf, and at your sole request. More information is
                      available in our Privacy Policy. You agree that your
                      decision to make available any sensitive or confidential
                      information is your sole responsibility and at your sole
                      risk. The Service has no control and makes no
                      representations as to the use or disclosure of information
                      provided to third parties. You agree that these third
                      party services are not under the Services control, and
                      that the Service is not responsible for any third
                      party&rsquo;s use of your information.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Intellectual Property
                  </Title>
                  <Text variant="small">
                    <p>
                      Service and its original content (excluding Content
                      provided by users), features and functionality are and
                      will remain the exclusive property of Jomo Labs LLC and
                      its licensors. Service is protected by copyright,
                      trademark, and other laws of the United States. Our
                      trademarks and trade dress may not be used in connection
                      with any product or service without the prior written
                      consent of Jomo Labs LLC.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Error Reporting and Feedback
                  </Title>
                  <Text variant="small">
                    <p>
                      You may provide us either directly at support@allo.finance
                      or via third party sites and tools with information and
                      feedback concerning errors, suggestions for improvements,
                      ideas, problems, complaints, and other matters related to
                      our Service (&ldquo;Feedback&rdquo;). You acknowledge and
                      agree that: (i) you shall not retain, acquire or assert
                      any intellectual property right or other right, title or
                      interest in or to the Feedback; (ii) Company may have
                      development ideas similar to the Feedback; (iii) Feedback
                      does not contain confidential information or proprietary
                      information from you or any third party; and (iv) Company
                      is not under any obligation of confidentiality with
                      respect to the Feedback. In the event the transfer of the
                      ownership to the Feedback is not possible due to
                      applicable mandatory laws, you grant Company and its
                      affiliates an exclusive, transferable, irrevocable,
                      free-of-charge, sub-licensable, unlimited and perpetual
                      right to use (including copy, modify, create derivative
                      works, publish, distribute and commercialize) Feedback in
                      any manner and for any purpose.
                    </p>
                    <p>
                      The third party sites and tools mentioned above include
                      the following:
                    </p>
                    <p>
                      <b>Bugsnag</b>
                    </p>
                    <p>
                      Bugsnag is a platform for monitoring and logging stability
                      of applications provided by Bugsnag Inc. Please read their
                      Privacy Policy here:
                      https://docs.bugsnag.com/legal/privacy-policy/
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Links To Other Web Sites
                  </Title>
                  <Text variant="small">
                    <p>
                      Our Service may contain links to third party web sites or
                      services that are not owned or controlled by Jomo Labs
                      LLC.
                    </p>
                    <p>
                      Jomo Labs LLC has no control over, and assumes no
                      responsibility for the content, privacy policies, or
                      practices of any third party web sites or services. We do
                      not warrant the offerings of any of these
                      entities/individuals or their websites.
                    </p>
                    <p>
                      YOU ACKNOWLEDGE AND AGREE THAT JOMO LABS LLC SHALL NOT BE
                      RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                      DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                      CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                      GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
                      PARTY WEB SITES OR SERVICES.
                    </p>
                    <p>
                      WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                      PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                      THAT YOU VISIT.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Disclaimer Of Warranty
                  </Title>
                  <Text variant="small">
                    <p>
                      THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS
                      IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. COMPANY
                      MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                      EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
                      OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN.
                      YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                      CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
                      YOUR SOLE RISK.
                    </p>
                    <p>
                      NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY
                      MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                      COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                      AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
                      FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
                      COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR
                      CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                      SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                      SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                      VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES
                      OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
                      WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                    </p>
                    <p>
                      COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                      WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                      INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                      MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                      PARTICULAR PURPOSE.
                    </p>
                    <p>
                      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT
                      BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Limitation Of Liability
                  </Title>
                  <Text variant="small">
                    <p>
                      EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                      OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR
                      ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
                      CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
                      ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
                      LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF
                      ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                      INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE,
                      OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                      CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT
                      LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY
                      DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY
                      YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES,
                      OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY
                      ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
                      PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART
                      OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
                      PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
                      THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO
                      NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE,
                      INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
                      LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Governing Law
                  </Title>
                  <Text variant="small">
                    <p>
                      These Terms shall be governed and construed in accordance
                      with the laws of State of California without regard to its
                      conflict of law provisions.
                    </p>
                    <p>
                      Our failure to enforce any right or provision of these
                      Terms will not be considered a waiver of those rights. If
                      any provision of these Terms is held to be invalid or
                      unenforceable by a court, the remaining provisions of
                      these Terms will remain in effect. These Terms constitute
                      the entire agreement between us regarding our Service and
                      supersede and replace any prior agreements we might have
                      had between us regarding Service.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Changes To Service
                  </Title>
                  <Text variant="small">
                    <p>
                      We reserve the right to withdraw or amend our Service, and
                      any service or material we provide via Service, in our
                      sole discretion without notice. We will not be liable if
                      for any reason all or any part of Service is unavailable
                      at any time or for any period. From time to time, we may
                      restrict access to some parts of Service, or the entire
                      Service, to users, including registered users.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Scope of license
                  </Title>
                  <Text variant="small">
                    <p>
                      You are given a non-transferable, non-exclusive,
                      non-sublicensable license to install and use the Service
                      on any devices that you own or control, with the exception
                      that such Service may be accessed and used by other
                      accounts associated with you via Family Sharing or volume
                      purchasing.
                    </p>
                    <p>
                      You may not reverse engineer, translate, disassemble,
                      integrate, decompile, remove, modify, combine, create
                      derivative works or updates of, adapt, or attempt to
                      derive the source code of the Licensed Application, or any
                      part thereof.
                    </p>
                    <p>
                      Violations of the obligations mentioned above, as well as
                      the attempt of such infringement, may be subject to
                      prosecution and damages.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Amendments To Terms
                  </Title>
                  <Text variant="small">
                    <p>
                      We may amend Terms at any time by posting the amended
                      terms on this site. It is your responsibility to review
                      these Terms periodically.
                    </p>
                    <p>
                      Your continued use of the Platform following the posting
                      of revised Terms means that you accept and agree to the
                      changes. You are expected to check this page frequently so
                      you are aware of any changes, as they are binding on you.
                    </p>
                    <p>
                      By continuing to access or use our Service after any
                      revisions become effective, you agree to be bound by the
                      revised terms. If you do not agree to the new terms, you
                      are no longer authorized to use Service.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Waiver And Severability
                  </Title>
                  <Text variant="small">
                    <p>
                      No waiver by Company of any term or condition set forth in
                      Terms shall be deemed a further or continuing waiver of
                      such term or condition or a waiver of any other term or
                      condition, and any failure of Company to assert a right or
                      provision under Terms shall not constitute a waiver of
                      such right or provision.
                    </p>
                    <p>
                      If any provision of Terms is held by a court or other
                      tribunal of competent jurisdiction to be invalid, illegal
                      or unenforceable for any reason, such provision shall be
                      eliminated or limited to the minimum extent such that the
                      remaining provisions of Terms will continue in full force
                      and effect.
                    </p>
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Google and Apple Requirements
                  </Title>
                  <Text variant="small">
                    <p>
                      <b>Apple</b>
                    </p>
                    <p>
                      The following applies if you obtain the Service from
                      Apple, Inc.’s (“Apple”) App Store. You acknowledge and
                      agree that these terms of service are solely between you
                      and the Company not Apple, and that Apple has no
                      responsibility for the Service or the contents thereof.
                      Your use of the Service must comply with the App Store
                      Terms of Service or other terms applicable to the use of
                      the Service. You acknowledge that Apple has no obligation
                      whatsoever to furnish any maintenance and support services
                      with respect to the Service. In the event of any failure
                      of the Service to conform to any applicable warranty, you
                      may notify Apple and Apple will be responsible for any
                      refund of the purchase price for the Service, if any, to
                      you. To the maximum extent permitted by applicable law,
                      Apple will have no other warranty obligation whatsoever
                      with respect to the Service. You acknowledge that Apple is
                      not responsible for addressing any claims by you or by any
                      third party relating to the Service or your possession
                      and/or use of the Service, including but not limited to,
                      (i) product liability claims, (ii) any claim that the
                      Service fails to conform to any applicable legal or
                      regulatory requirement, and (iii) claims arising under
                      consumer protection or similar legislation. All such
                      claims are governed solely by these terms of service and
                      any law applicable to Company as the provider of the
                      Service. You acknowledge that in the event of a claim that
                      the Service and your possession and use thereof infringes
                      a third party’s intellectual property rights, the Company,
                      not Apple, will be solely responsible for the
                      investigation, defense, settlement, and discharge of any
                      such intellectual property infringement claim to the
                      extent required by these terms of service. You represent
                      and warrant that (a) you are not located in a country that
                      is subject to a U.S. government embargo, or that has been
                      designated by the U.S. government as a “terrorist
                      supporting’’ country; and (b) you are not listed on any
                      U.S. government list of prohibited or restricted parties.
                      You and the Company acknowledge and agree that Apple and
                      its subsidiaries are third-party beneficiaries of these
                      terms of service and that, upon your acceptance of these
                      terms of service, Apple will have the right (and will be
                      deemed to have accepted the right) to enforce these terms
                      of service against you as a third-party beneficiary
                      thereof.
                    </p>
                    <p>
                      <b>Google Play</b>
                    </p>
                    <p>
                      The following applies if you obtain the Service from
                      Google Play (or its successors) operated by Google, Inc.
                      (“Google”). You acknowledge and agree that these terms of
                      service are solely between you and the Company, not Google
                      and that Google has no responsibility for the Service or
                      the contents thereof. Your use of the Service must comply
                      with the Google Play Terms of Use or other terms
                      applicable to the use of the Service. You acknowledge that
                      Google bears no obligation or responsibility for
                      maintenance and support services with respect to the
                      Service. To the maximum extent permitted by applicable
                      law, Google will have no warranty obligation whatsoever
                      with respect to the Service and any claims, losses,
                      liabilities, damages, costs, or expenses attributable to
                      any failure of the Service to conform to any warranty will
                      be solely governed by these terms of service and any law
                      applicable to the Company as a provider of the Service.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Acknowledgement
                  </Title>
                  <Text variant="small">
                    <p>
                      BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                      ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                      AGREE TO BE BOUND BY THEM.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contact Us
                  </Title>
                  <Text variant="small">
                    <p>
                      Please send your feedback, comments, requests for
                      technical support:
                    </p>
                    <p>
                      By email:
                      <a href="mailto:support@allo.finance">
                        support@allo.finance
                      </a>
                      .
                    </p>
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
